import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
        marginTop: "20px"
    },
    space: {
        marginTop: "20px"
    },
    title: {

    }
}));

export default function PaperSheet( props ) {
    const classes = useStyles();

    return (
        <div>
            <Paper className={classes.root}>
                <Typography variant="h5" component="h3" >
                    Step 3. Sortify
                </Typography>
                <Typography className={classes.space} component="p" align='justify'>
                    Select tracks for your playlist by clicking on them. When you're done, create a new playlist by giving it a name OR add the selected tracks to a playlist you already have.
                </Typography>
            </Paper>
        </div>
    );
}
