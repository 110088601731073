import Card from "../search/SearchResults";
import {Link} from "react-router-dom";
import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

export const NoSearchResultsCard = (props) => {

    return (
        <Card className={props.card}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    We still haven't found what you're looking for...
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                    across all continents except Antarctica
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="primary">
                    <Link to="search">Search again?</Link>
                </Button>
            </CardActions>
        </Card>
    );
};
