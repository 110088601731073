import React from 'react';
import { Link , Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';


const loginStyles = makeStyles(theme => ({
    root: {
        height: '100vh'
    },
    paperRoot: {
        margin: '0 auto'
    },
    paper: {
        margin: theme.spacing(15, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(2)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    spotify: {
        padding: theme.spacing(3),
        backgroundColor: '#1DB954',
        color: 'white'
    },
    spotifyButton: {
        marginRight: theme.spacing(1)
    }
}));

export const Login = (
    props
) => {
    const classes = loginStyles();
    const { setAccessToken, setRefreshToken, setLoggedIn, appBaseUrl, serverPort  } = props;
    console.log(`URL: ${appBaseUrl} -- SERVER_PORT: ${serverPort}`);
    /**
         * Obtains parameters from the hash of the URL
         * @return Object
         */
    function getHashParams() {
        var hashParams = {};
        var e, r = /([^&;=]+)=?([^&;]*)/g,
            q = window.location.hash.substring(1);
        while (e = r.exec(q)) {
            hashParams[e[1]] = decodeURIComponent(e[2]);
        }
        return hashParams;
    }

    var params = getHashParams();
    var access_token = params.access_token;
    var refresh_token = params.refresh_token;
    var error = params.error;

    if(error){
        alert('There was an error during the authentication');
    } else if (access_token && refresh_token) {
        setAccessToken(access_token);
        setRefreshToken(refresh_token);
        setLoggedIn(true);
        return <Redirect to='/search'/>
    }

    return (
        <Grid container component='main' className={classes.root}>
            {/* <Grid item xs={false} sm={4} md={7} component="div" className={classes.image} /> */}
            <Grid item xs={12} sm={8} md={5} component={Paper} className={classes.paperRoot}elevation={6} square>
                <div className={classes.paper}>
                    <Typography component='h1' variant='h5'>
                        Sign in
                    </Typography>
                    <form className={classes.form} noValidate>
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            component={Link}
                            to='/search'
                            className={classes.submit}
                        >
                            Go to search
                        </Button>
                    </form>
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        href={`${appBaseUrl}/api/login`}
                        className={classes.spotify}
                    >
                        <img src="Spotify_Icon_RGB_White.png" alt="Spotify Logo"
                            height="30" width="30" className={classes.spotifyButton} />
                        Login with Spotify
                    </Button>
                </div>
            </Grid>
        </Grid>
    )
}

export default Login;

