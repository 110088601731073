export const msToTime = (duration) => {
    const seconds = parseInt((duration / 1000) % 60);
    const minutes = parseInt((duration / (1000 * 60)) % 60);

    const minutesString = (minutes < 10) ? "0" + minutes : minutes;
    const secondsString = (seconds < 10) ? "0" + seconds : seconds;

    return `${minutesString}:${secondsString}`
};

export const getTrack = (track) => {
        const {id, name, duration_ms, album, artists, popularity, preview_url} = track;
        const {name: artist} = artists[0];
        const {name: albumName, images} = album;
        const [albumImage] = images.sort((a, b) => a.height - b.height); // the smallest image
        return {
            id,
            name,
            length: msToTime(duration_ms),
            popularity,
            preview_url,
            artist,
            albumName,
            albumImage
        }
};

export const sortByPopularity = (a, b) => b.popularity - a.popularity; // descending
