import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles(theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        color: theme.palette.primary.contrastText
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const CreateNewPlaylist = ({ onSave, isDisabled }) => {
    const classes = useStyles();
    const [playlistName, setPlaylistName] = useState('');

    const handleChange = (event) => {
        setPlaylistName(event.target.value);
    };

    return (
        <Grid container component="div" spacing={2}
              direction="row" justify="space-between" alignItems="center">
            <Grid item component="div">
                <Paper className={classes.root}>
                    <InputBase
                        className={classes.input}
                        placeholder="Enter Playlist Name"
                        onChange={handleChange}
                    />
                </Paper>
            </Grid>
            <Grid item component="div">
                <Button
                    type='submit'
                    color='secondary'
                    variant='contained'
                    disabled={isDisabled}
                    onClick={() => onSave(playlistName)}
                >
                    Save
                </Button>
            </Grid>
        </Grid>
    );
};

export default CreateNewPlaylist;
