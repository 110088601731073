import React from 'react';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    snackbar: {
        backgroundColor: theme.palette.secondary.main
    },
    snackbarInfoIcon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    snackbarCloseIcon: {
        fontSize: 20
    },
    snackbarMessage: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const NoSearchResultsSnackbar = ({
    showError,
    setShowError
}) => {
    const classes = useStyles();

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={showError}
            onClose={() => setShowError(false)}
            autoHideDuration={6000}
        >
            <SnackbarContent
                className={classes.snackbar}
                message={
                    <span id="client-snackbar" className={classes.snackbarMessage}>
                        <ErrorIcon className={classes.snackbarInfoIcon} />
                        No results found!  Try a different search.
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="close" color="inherit"
                        onClick={() => setShowError(false)}
                    >
                        <CloseIcon className={classes.snackbarCloseIcon} />
                    </IconButton>
                ]}
            />
        </Snackbar>
    );
};

export default NoSearchResultsSnackbar;
