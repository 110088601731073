import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import PlaylistImage from './PlaylistImage';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper
    },
    gridList: {
        width: 500,
        height: 450,
        cursor: 'pointer'
    }
}));

const SearchPlaylists = ({
    isOpen,
    setPlaylist,
    selectedPlaylist,
    playlists,
    close
}) => {
    const classes = useStyles();

    const handleClose = () => {
        setPlaylist(selectedPlaylist);
    };

    const handleTileClick = value => {
        setPlaylist(value);
        close();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={isOpen}>
            <DialogTitle id="simple-dialog-title">Select Playlist to Update</DialogTitle>
            <div className={classes.root}>
                <GridList cellHeight={180} className={classes.gridList}>

                    {playlists.map(playlist => (
                        <GridListTile key={playlist.id} onClick={() => handleTileClick(playlist)}>
                            <PlaylistImage playlist={playlist} />
                            <GridListTileBar
                                title={playlist.name}
                                subtitle={<span># tracks: {playlist.numTracks}</span>}
                            />
                        </GridListTile>
                    ))}

                </GridList>
            </div>
        </Dialog>
    );
};

export default SearchPlaylists;
