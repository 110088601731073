import React from 'react';


const PlaylistImage = ({ playlist }) => {
    const isImageFound = !!playlist.image;

    let imageSrc = "No_Image_Available.jpg";
    if (isImageFound) {
        imageSrc = playlist.image.url;
    }

    return (
        <img src={imageSrc}
             alt={playlist.name}
             width="100%"
        />
    );
};

export default PlaylistImage;
