import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { getSimilarTracks, getTopTracksByTag } from '../requests';


const FilterButton = ({
    history,
    selectedTrack,
    setIsLoading,
    setSeedTrackResults,
    accessToken,
    findSimilar,
    filterTag,
    setDisplayNoResults,
    isDisabled
}) => {
    const handleGoClick = async() => {
        setIsLoading(true);

        let tracks = [];
        if (findSimilar) {
            tracks = await getSimilarTracks(selectedTrack.artist, selectedTrack.name, accessToken);
        }
        else {
            tracks = await getTopTracksByTag(filterTag, accessToken);
        }

        setSeedTrackResults(tracks);

        if (!tracks.length){
            setDisplayNoResults(true);
        } else {
            history.push('build-playlist');
        }

        setIsLoading(false);
    };

    return (
        <Button
            type='submit'
            fullWidth
            color='primary'
            variant='outlined'
            disabled={isDisabled}
            onClick={handleGoClick}>
            Find more songs!
        </Button>
    );
};

const HistoryFilterButton = withRouter(FilterButton);


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    selectRoot: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        borderWidth: '1px',
        borderColor: theme.palette.primary.main
    },
    selectLabel: {
        color: theme.palette.primary.main,
        borderWidth: '1px',
        borderColor: theme.palette.primary.main,
        '&:not([input.value=""])': {
            color: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main
        }
    },
    selectInput: {
        color: theme.palette.primary.main,
        borderWidth: '1px',
        borderColor: theme.palette.primary.main,
        '&:not([value=""])': {
            color: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main
        }
    }
}));

const SeedFilter = ({
    selectedTrack,
    seedTracks,
    setSeedTrackResults,
    setIsLoading,
    accessToken,
    tags,
    setDisplayNoResults
}) => {
    const classes = useStyles();
    const [findSimilar, setFindSimilar] = useState(false);
    const [filterTag, setFilterTag] = useState('');
    const [isFilterDisabled, setFilterDisabled] = useState(false);

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    React.useEffect(() => {
        if (tags.length) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, [tags.length]);

    React.useEffect(() => {
        // only one or the other
        if (findSimilar) {
            setFilterTag('');
        }
    }, [findSimilar]);

    React.useEffect(() => {
        // only one or the other
        if (filterTag !== '') {
            setFindSimilar(false);
        }
    }, [filterTag]);

    React.useEffect(() => {
        if (!findSimilar && filterTag === '') {
            setFilterDisabled(true);
        } else {
            setFilterDisabled(false);
        }
    }, [findSimilar, filterTag]);

    const toggleFindSimilar = () => {
        setFindSimilar(!findSimilar);
    };

    const handleSelectTag = event => {
        setFilterTag(event.target.value);
    };

    return (
        <Grid container component="div" spacing={2}
            direction="row" justify="space-between" alignItems="baseline">

            <Grid container item component="div" spacing={1} xs={8}
                  direction="row" justify="space-between" alignItems="baseline">

                <Grid item component="div" xs={3}>
                    <Typography color="secondary">Select filter criteria: </Typography>
                </Grid>

                <Grid item component="div" xs={4}>
                    <Chip
                        label="Find Similar Songs"
                        color={ findSimilar ? "secondary" : "primary" }
                        component="button"
                        clickable
                        onClick={toggleFindSimilar}
                    />
                </Grid>

                <Grid item component="div" xs={4}>

                    { tags.length &&
                        <form className={classes.selectRoot} autoComplete="off">
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel ref={inputLabel} className={classes.selectLabel}>
                                    Filter Tag
                                </InputLabel>
                                <Select className={classes.selectInput}
                                    value={filterTag}
                                    onChange={handleSelectTag}
                                    labelWidth={labelWidth}
                                    inputProps={{
                                        name: 'tag',
                                        id: 'tag',
                                    }}
                                >
                                    <MenuItem key="none" value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {tags.map((tag) => (
                                        <MenuItem key={tag} value={tag}>{tag}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </form>
                    }
                </Grid>
            </Grid>

            <Grid item component="div" xs={3}>
                <HistoryFilterButton selectedTrack={selectedTrack}
                                     setSeedTrackResults={setSeedTrackResults}
                                     seedTracks={seedTracks}
                                     setIsLoading={setIsLoading}
                                     accessToken={accessToken}
                                     findSimilar={findSimilar}
                                     filterTag={filterTag}
                                     setDisplayNoResults={setDisplayNoResults}
                                     isDisabled={isFilterDisabled}
                />
            </Grid>
        </Grid>
    );
};

export default SeedFilter;
