import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles(theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        color: theme.palette.primary.contrastText
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const UpdatePlaylist = ({ onUpdate, isDisabled, playlist, handleShowPlaylistSearch }) => {
    const classes = useStyles();
    const playlistName = playlist.name || '';

    return (
        <Grid container component="div" spacing={2}
              direction="row" justify="space-between" alignItems="center">
            <Grid item component="div">
                <Paper className={classes.root}>
                    <InputBase
                        className={classes.input}
                        placeholder="Pick a Playlist"
                        value={playlistName}
                        disabled
                    />
                    <Divider className={classes.divider} orientation="vertical" />
                    <IconButton className={classes.iconButton} aria-label="search"
                        onClick={handleShowPlaylistSearch}
                    >
                        <SearchIcon color="secondary" />
                    </IconButton>
                </Paper>
            </Grid>
            <Grid item component="div">
                <Button
                    type='submit'
                    color='secondary'
                    variant='contained'
                    disabled={isDisabled}
                    onClick={() => onUpdate(playlist)}
                >
                    Update
                </Button>
            </Grid>
        </Grid>
    );
};

export default UpdatePlaylist;

