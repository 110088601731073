import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { getPlaylists, getCurrentUserProfile } from './requests';

import Login from './login/Login';
import Search from './search/Search';
import SearchResults from './search/SearchResults';
import BuildPlaylist from './playlist/BuildPlaylist';
import ProgressBar from "./components/ProgressBar";

import logo from './logo.svg';
import Header from './Header';
import './App.css'


const theme = createMuiTheme({
    palette: {
        "type": "dark",
        "common": {
            "black": "#000",
            "white": "#fff"
        },
        "background": {
            "paper": "rgba(221, 245, 253, 1)",
            "default": "rgba(8, 0, 0, 1)",
        },
        "primary": {
            "light": "rgba(126, 184, 219, 1)",
            "main": "rgba(153, 254, 255, 1)",
            "dark": "rgba(33, 135, 195, 1)",
            "contrastText": "rgba(11, 11, 11, 1)"
        },
        "secondary": {
            "light": "rgba(248, 188, 92, 1)",
            "main": "rgba(248, 136, 37, 1)",
            "dark": "rgba(214, 48, 27, 1)",
            "contrastText": "#fff"
        },
        "error": {
            "light": "#e57373",
            "main": "#f44336",
            "dark": "#d32f2f",
            "contrastText": "#fff"
        },
        "text": {
            "primary": "rgba(0, 0, 0, 0.87)",
            "secondary": "rgba(0, 0, 0, 0.54)",
            "disabled": "rgba(0, 0, 0, 0.38)",
            "hint": "rgba(0, 0, 0, 0.38)"
        }
    }
});

function App({
    nodeEnviroment,
    clientPort,
    serverPort,
    spotifyApiId,
    spotifyClientSecret,
    appBaseUrl
}) {
    const [userProfile, setUserProfile] = useState();
    const [playlists, setPlaylists] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [seedTracks, setSeedTrackResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [accessToken, setAccessToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [refreshPlaylists, setPlaylistsToRefresh] = useState(false);

    useEffect(() => {
        if (accessToken) {
            setIsLoading(true);

            // store user profile
            getCurrentUserProfile(accessToken)
                .then((user) => {
                    return setUserProfile(user);
                })
                .then(() => {
                    // load playlists
                    return getPlaylists(accessToken).then((playlists) => {
                        return setPlaylists(playlists);
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [accessToken, setIsLoading, setUserProfile, setPlaylists]);

    useEffect(() => {
        if (accessToken) {
            setIsLoading(true);

            getPlaylists(accessToken).then((playlists) => {
                return setPlaylists(playlists);
            }).finally(() => {
                setPlaylistsToRefresh(false);
                setIsLoading(false);
            });
        }
    }, [accessToken, refreshPlaylists, setPlaylistsToRefresh]);

    return (
        <>
            <CssBaseline />
            <MuiThemeProvider theme={theme}>
                <Router>
                    <div className="app">
                        <Header />
                        {isLoading && <ProgressBar />}

                        <main className="app-wrapper">
                            <Route exact path="/" render={(props) => (
                                <Login
                                    {...props}
                                    setAccessToken={setAccessToken}
                                    setRefreshToken={setRefreshToken}
                                    setLoggedIn={setLoggedIn}
                                    appBaseUrl={appBaseUrl}
                                    serverPort={serverPort}
                                />
                            )}
                            />
                            <Route
                                path="/search"
                                render={(props) => (
                                    <Search
                                        {...props}
                                        playlists={playlists}
                                        setSearchResults={setSearchResults}
                                        setIsLoading={setIsLoading}
                                        accessToken={accessToken}
                                        loggedIn={loggedIn}
                                        setLoggedIn={setLoggedIn}
                                    />
                                )}
                            />
                            <Route
                                path="/search-results"
                                render={(props) => (
                                    <SearchResults
                                        {...props}
                                        searchResults={searchResults}
                                        setSearchResults={setSearchResults}
                                        setIsLoading={setIsLoading}
                                        setSeedTrackResults={setSeedTrackResults}
                                        seedTracks={seedTracks}
                                        accessToken={accessToken}
                                        setLoggedIn={setLoggedIn}
                                    />
                                )}
                            />
                            <Route
                                path="/build-playlist"
                                render={(props) => (
                                    <BuildPlaylist
                                        {...props}
                                        userProfile={userProfile}
                                        seedTracks={seedTracks}
                                        accessToken={accessToken}
                                        setLoggedIn={setLoggedIn}
                                        setIsLoading={setIsLoading}
                                        playlists={playlists}
                                        setPlaylistsToRefresh={setPlaylistsToRefresh}
                                    />
                                )}
                            />
                        </main>
                        <div id={'hackbit-vote-widget'}/>
                    </div>
                </Router>
            </MuiThemeProvider>
        </>
    );
}

export default App;
