import React from 'react';
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        color: theme.palette.primary.main,
        backgroundColor: '#000',
    },
    iconButton: {
        padding: 10
    },
    icon: {
        color: theme.palette.primary.main,
        marginTop: "7px"

    }
}));

const Header = ( ) => {
    const classes = useStyles();

return (
    <AppBar position="static">
        <div className={classes.root}>
        <Toolbar>
            <Grid container>
                <Grid item xs={11}>
                    <Typography variant="h4" component="h1" flex="1">
                    Sortify
                    </Typography>
                </Grid>
                <Grid item xs={1}>
            <Link
                to='/search'
            >
                <SearchIcon className={classes.icon}/>
            </Link>
                </Grid>
            </Grid>
        </Toolbar>
        </div>
    </AppBar>
    );
};

export default Header;
