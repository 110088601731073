import React, { useCallback, useState } from 'react';
import {Link, Redirect} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container";
import Slide from '@material-ui/core/Slide';

import TracksTable from '../components/TracksTable';
import { getTopTags } from '../requests';
import {NoSearchResultsCard} from "../components/NoSearchResultsCard";
import NoSearchResultsSnackbar from '../components/NoSearchResultsSnackbar';
import StepTwoCard from "../components/StepTwoCard";

import Search from "./Search";
import SeedFilter from './SeedFilter';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: "20px"
    },
    noSearchResults: {
        marginTop: theme.spacing(4)
    },
    card: {},
    media: {},
    filterRow: {
        backgroundColor: '#000',
        padding: '10px 20px',
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        zIndex: 1
    }
}));

const SearchResults = ({
    setSearchResults,
    searchResults,
    history,
    setIsLoading,
    setSeedTrackResults,
    seedTracks,
    accessToken,
    setLoggedIn
}) => {
    const classes = useStyles();
    const [selectedTrack, setSelectedTrack] = useState();
    const [tags, setTags] = useState([]);
    const [displayNoResults, setDisplayNoResults] = useState(false);

    const onSelectHandler = useCallback(async (selectedTrack) => {
        if (selectedTrack.name){
            setSelectedTrack(selectedTrack);
            const topTags = await getTopTags(selectedTrack.artist, selectedTrack.name);
            setTags(topTags);
        }
    }, [selectedTrack, setSelectedTrack, setTags]);

    if (!accessToken) {
        setLoggedIn(false);
        return <Redirect to='/'/>
    }

    if (!searchResults.length) {
        return (
            <div>
                <Search
                    setSearchResults={setSearchResults}
                    setIsLoading={setIsLoading}
                    history={history}
                />
                <Container maxWidth="md">
                    <Grid container className={classes.noSearchResults}>
                        <Grid item xs={12}>
                            <NoSearchResultsCard card={classes.card} />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }

    return (
        <Container maxWidth="xl">
            <Grid item md>
                <StepTwoCard className={classes.card}/>
            </Grid>

        <Paper className={classes.root}>
            <TracksTable allowMultipleSelect={false} tracks={searchResults} onSelect={onSelectHandler} />

            <Slide direction="up" in={!!selectedTrack} mountOnEnter unmountOnExit>
                <div className={classes.filterRow}>
                    <SeedFilter selectedTrack={selectedTrack}
                                setSeedTrackResults={setSeedTrackResults}
                                seedTracks={seedTracks}
                                setIsLoading={setIsLoading}
                                accessToken={accessToken}
                                tags={tags}
                                setDisplayNoResults={setDisplayNoResults}
                    />
                </div>
            </Slide>

            </Paper>

            <NoSearchResultsSnackbar showError={displayNoResults} setShowError={setDisplayNoResults} />
        </Container>
    );
};

export default SearchResults;
