import React, {useCallback, useState, useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';

import TracksTable from '../components/TracksTable';

import SearchPlaylists from './SearchPlaylists';
import PlaylistControlPanel from './PlaylistControlPanel';
import Grid from "@material-ui/core/Grid";
import StepThreeCard from "../components/StepThreeCard";
import Search from '../search/Search';
import Container from '@material-ui/core/Container';
import { NoSearchResultsCard } from '../components/NoSearchResultsCard';



const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    resultCell: {},
    noSearchResults: {
        marginTop: theme.spacing(4)
    },
    snackbar: {
        backgroundColor: theme.palette.primary.main
    },
    snackbarInfoIcon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    snackbarCloseIcon: {
        fontSize: 20
    },
    snackbarMessage: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const BuildPlaylist = ({
    accessToken,
    setLoggedIn,
    setIsLoading,
    userProfile,
    playlists,
    seedTracks,
    setPlaylistsToRefresh
}) => {
    const classes = useStyles();

    const [playlist, setPlaylist] = useState('');
    const [showPlaylistSearch, setShowPlaylistSearch] = useState(false);
    const [selectedTracks, setSelectedTracks] = useState([]);
    const [disableControlPanel, setControlPanelDisabled] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const handleShowPlaylistSearch = useCallback(() => {
        setShowPlaylistSearch(true);
    }, []);

    useEffect(() => {
        setControlPanelDisabled(!selectedTracks || Object.keys(selectedTracks).length === 0);
    }, [selectedTracks]);

    if (!accessToken){
        setLoggedIn(false);
        return <Redirect to='/'/>
    }

    if (!seedTracks.length) {
        return (
            <div>
                <Container maxWidth="md">
                    <Grid container className={classes.noSearchResults}>
                        <Grid item xs={12}>
                            <NoSearchResultsCard card={classes.card} />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }

    return (
        <div>
            <Grid container >
                <Grid item xs={12}>
                    <StepThreeCard />
                </Grid>
            </Grid>

            <PlaylistControlPanel
                accessToken={accessToken}
                setIsLoading={setIsLoading}
                userProfile={userProfile}
                playlist={playlist}
                handleShowPlaylistSearch={handleShowPlaylistSearch}
                selectedTracks={selectedTracks}
                isDisabled={disableControlPanel}
                setPlaylistsToRefresh={setPlaylistsToRefresh}
                setShowSuccess={setShowSuccess}
            />

            <Paper className={classes.root}>
                <TracksTable allowMultipleSelect={true} tracks={seedTracks} onSelect={setSelectedTracks} />
            </Paper>

            <SearchPlaylists
                isOpen={showPlaylistSearch}
                selectedPlaylist={playlist}
                playlists={playlists}
                setPlaylist={setPlaylist}
                close={() => setShowPlaylistSearch(false)}
            />

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={showSuccess}
                onClose={() => setShowSuccess(false)}
                autoHideDuration={6000}
            >
                <SnackbarContent
                    className={classes.snackbar}
                    message={
                        <span id="client-snackbar" className={classes.snackbarMessage}>
                            <InfoIcon className={classes.snackbarInfoIcon} />
                            Successfully saved {playlist.name}!
                        </span>
                    }
                    action={[
                        <IconButton key="close" aria-label="close" color="inherit"
                            onClick={() => setShowSuccess(false)}
                        >
                            <CloseIcon className={classes.snackbarCloseIcon} />
                        </IconButton>
                    ]}
                />
            </Snackbar>
        </div>
    );
};

export default BuildPlaylist;
