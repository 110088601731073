import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { addToPlaylist, createPlaylist } from '../requests';

import CreateNewPlaylist from './CreateNewPlaylist';
import UpdatePlaylist from './UpdatePlaylist';


const useStyles = makeStyles(theme => ({
    controlPanel: {
        flexGrow: 1,
        width: '100%'
    },
    playlistContainer: {
        paddingTop: "20px",
        paddingBottom: "20px"
    }
}));

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
};

const tapProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

export const PlaylistControlPanel = ({
    accessToken,
    setIsLoading,
    userProfile,
    playlist,
    handleShowPlaylistSearch,
    selectedTracks,
    isDisabled,
    setPlaylistsToRefresh,
    setShowSuccess
}) => {
    const classes = useStyles();
    const [playlistOption, setPlaylistOption] = useState(0);

    const handleChange = (event, newValue) => {
        setPlaylistOption(newValue);
    };

    const handleUpdate = useCallback(() => {
        setShowSuccess(true);
        setPlaylistsToRefresh(true);
    }, [setShowSuccess, setPlaylistsToRefresh]);

    const handleCreateNewPlaylist = useCallback((playlistName) => {
        setIsLoading(true);
        createPlaylist(accessToken, playlistName, userProfile.id, selectedTracks)
            .then(handleUpdate)
            .finally((() => setIsLoading(false)));
    }, [setIsLoading, accessToken, userProfile, selectedTracks, handleUpdate]);

    const handleUpdatePlaylist = useCallback((playlist) => {
        setIsLoading(true);
        addToPlaylist(accessToken, playlist.id, selectedTracks)
            .then(handleUpdate)
            .finally((() => setIsLoading(false)));
    }, [setIsLoading, accessToken, selectedTracks, handleUpdate]);

    return (
        <Grid container component="div" justify="center" alignItems="center" className={classes.playlistContainer}>
            <Grid item component="div">
                <Paper className={classes.controlPanel}>
                    <Tabs
                        value={playlistOption}
                        component="div"
                        onChange={handleChange}
                        centered
                    >
                        <Tab label="Create a New Playlist" {...tapProps(0)} />
                        <Tab label="Update a Playlist" {...tapProps(1)} />
                    </Tabs>

                    <TabPanel value={playlistOption} index={0}>
                        <CreateNewPlaylist
                            onSave={handleCreateNewPlaylist}
                            isDisabled={isDisabled}
                        />
                    </TabPanel>
                    <TabPanel value={playlistOption} index={1}>
                        <UpdatePlaylist
                            isDisabled={isDisabled}
                            playlist={playlist}
                            onUpdate={handleUpdatePlaylist}
                            handleShowPlaylistSearch={handleShowPlaylistSearch}
                        />
                    </TabPanel>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default PlaylistControlPanel;
