import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
    flipped: {
      transform: 'rotate(0deg)'
    }
});

export default function LinearQuery() {
  const classes = useStyles();

  return (
    <div>
      <LinearProgress variant="query" />
      <LinearProgress color="secondary" variant="query" className={classes.flipped}/>
    </div>
  );
}
