import axios from 'axios';

import { getTrack, sortByPopularity } from './utils';


// Constants
const LAST_FM_BASE_URL = 'https://ws.audioscrobbler.com/2.0/';
const LAST_FM_API_KEY = '93cffcfbccb8ec6af9f1dd0426cf5f36';

// Spotify
export const getCurrentUserProfile = (access_token) => {
    return axios({
        method: 'get',
        url: 'https://api.spotify.com/v1/me',
        headers: {
            'Authorization': `Bearer ${access_token}`
        },
        params: {}
    })
    .then((response) => {
        if (response && response.data) {
            return response.data;
        }
        return {};
    });
};

export const getPlaylists = (access_token) => {
    return axios({
        method: 'get',
        url: 'https://api.spotify.com/v1/me/playlists',
        headers: {
            'Authorization': `Bearer ${access_token}`
        },
        params: {}
    })
    .then((response) => {
        if (response && response.data && response.data.items) {
            return response.data.items;
        }
        return [];
    })
    .then((playlists) => {
        return playlists.map((playlist) => {
            const { id, images, name, tracks } = playlist;
            const numTracks = tracks.total;
            const [image] = images.sort((a, b) => a.height = b.height); // the smallest image
            return {
                id,
                name,
                image,
                numTracks
            }
        })
    });
};

export const getPlaylistTracks = (access_token, playlistId) => {
    return axios({
        method: 'get',
        url: `https://api.spotify.com/v1/playlists/${playlistId}/tracks`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        },
        params: {}
    })
    .then((response) => {
        if(response && response.data && response.data.items) {
            return response.data.items;
        }

        return [];
    })
    .then((tracks) => {
        return tracks
            .map((searchResult) => getTrack(searchResult.track))
            .sort(sortByPopularity);
    })
};

export const getTracks = (access_token, params) => {
    return axios({
        method: 'get',
        url: 'https://api.spotify.com/v1/search',
        headers: {
            'Authorization': 'Bearer ' + access_token
        },
        params
    }).then((response) => {
        if(response && response.data && response.data.tracks && response.data.tracks.items) {
            return response.data.tracks.items;
        }

        return [];
    }).then((tracks) => {
        return tracks
            .map((searchResult) => getTrack(searchResult))
            .sort(sortByPopularity);
    });
};

export const getSpotifyTrack = (access_token, song, artist) => {
    return axios({
        method: 'get',
        url: 'https://api.spotify.com/v1/search',
        headers: {
            'Authorization': 'Bearer ' + access_token
        },
        params: {
            q: artist + ' ' + song,
            limit: 1,
            market: 'US',
            type: 'track'
        }
    });
};

export const addToPlaylist = (access_token, playlistId, tracks) => {
    const trackURIs = Object.keys(tracks).map((trackId) => `spotify:track:${trackId}`);

    return axios({
        method: 'post',
        url: `https://api.spotify.com/v1/playlists/${playlistId}/tracks`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        },
        params: {
            uris: trackURIs.join(',')
        }
    })
};

export const createPlaylist = (access_token, playlistName, userId, tracks) => {
    return axios({
        method: 'post',
        url: `https://api.spotify.com/v1/users/${userId}/playlists`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        },
        data: {
            name: playlistName
        }
    })
    .then((response) => {
        if (response && response.data && response.data.id) {
            const playlistId = response.data.id;

            return addToPlaylist(access_token, playlistId, tracks);
        }
    })
};

// Last.FM
export const getTopTags = async (artist, track, api_key=LAST_FM_API_KEY) => {
    let fmResponse = await axios({
        method: 'get',
        url: LAST_FM_BASE_URL,
        params: {
            method: 'track.gettoptags',
            artist,
            track,
            api_key,
            format: 'json'
        }
    });

    if (fmResponse && fmResponse.data && fmResponse.data.toptags && fmResponse.data.toptags.tag) {
        return fmResponse.data.toptags.tag.map((tag) => tag && tag.name);
    }

    return [];
};

export const getTopTracksByTag = async(tag, access_token, api_key=LAST_FM_API_KEY) => {
    let lastFMResponse = await axios({
       method: 'get',
       url: LAST_FM_BASE_URL,
       params: {
           method: 'tag.gettoptracks',
           tag,
           limit: 30,
           api_key,
           format: 'json'
       }
    });
    let fmTopTracks = lastFMResponse.data.tracks.track;
    let promises = fmTopTracks.map((track) => getSpotifyTrack(access_token, track.artist.name, track.name));
    let results = await Promise.all(promises);
    return results
        .filter((result) => result.data.tracks.items[0])
        .map((result) => result.data.tracks.items[0])
        .map((searchResult) => getTrack(searchResult))
        .sort(sortByPopularity);

};

export const getSimilarTracks = async (artist, track, access_token, api_key=LAST_FM_API_KEY) => {
    let lastFMResponse = await axios({
        method: 'get',
        url: LAST_FM_BASE_URL,
        params: {
            method: 'track.getsimilar',
            artist,
            track,
            api_key,
            limit: 25,
            format: 'json'
        }
    });
    let similarLastFMTracks = lastFMResponse.data.similartracks.track;
    let promises = similarLastFMTracks.map((track) => getSpotifyTrack(access_token, track.artist.name, track.name));
    let results = await Promise.all(promises);
    return results
        .filter((result) => result.data.tracks.items[0])
        .map((result) => result.data.tracks.items[0])
        .map((searchResult) => getTrack(searchResult))
        .sort(sortByPopularity);
};
