import React, { useState, useCallback, useEffect} from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    tableWrapper: {
        overflow: 'auto',
        cursor: 'pointer'
    },
    tableHeadCell: {
        color: theme.palette.secondary.main,
        backgroundColor: '#1a2235e0',
        fontWeight: 'bold',
        fontSize: 16
    },
    highlightedTrack: {
        backgroundColor: theme.palette.primary.main
    }
}));

const columns = [
    {id: 'name', label: 'Title'},
    {id: 'artist', label: 'Artist'},
    {id: 'albumName', label: 'Album'},
    {id: 'length', label: 'Length'}
];

const TracksTable = ({
    tracks,
    onSelect,
    allowMultipleSelect
}) => {
    const classes = useStyles();
    const [selectedTracks, setSelectedTracks] = useState({});

    const handleSelect = useCallback((track) => {
        if (allowMultipleSelect) {
            const alreadySelected = !!selectedTracks[track.id];

            if (alreadySelected) {
                // remove from selected
                const newSelectedTracks = {...selectedTracks};
                delete newSelectedTracks[track.id];
                setSelectedTracks(newSelectedTracks);
            } else {
                // add to selected
                setSelectedTracks({
                    ...selectedTracks,
                    [track.id]: track
                });
            }

        } else {
            setSelectedTracks(track);
        }
    }, [setSelectedTracks,selectedTracks, allowMultipleSelect]);

    const isSelected = useCallback((track) => {
        if (allowMultipleSelect) {
            return selectedTracks && typeof selectedTracks[track.id] !== 'undefined'
        } else {
            return selectedTracks && track.id === selectedTracks.id
        }
    }, [selectedTracks, allowMultipleSelect]);

    useEffect(() => {
        onSelect(selectedTracks);
    }, [selectedTracks, onSelect]);

    return (
        <div className={classes.tableWrapper}>

            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {columns.map(column => (
                            <TableCell
                                key={`header-${column.id}`}
                                className={classes.tableHeadCell}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {tracks.map(track => {
                        return (
                            <TableRow
                                hover
                                key={track.id}
                                onClick={() => handleSelect(track)}
                            >
                                {columns.map(column => {
                                    return (
                                        <TableCell
                                            key={`${track.id}-${column.id}`}
                                            className={
                                                isSelected(track) ? classes.highlightedTrack : ''
                                            }
                                        >
                                            {column.id === 'name'
                                                ? <Grid container component="div" spacing={2} wrap="nowrap"
                                                        justify="flex-start" alignItems="center"
                                                >
                                                    <Grid item component="div">
                                                        <img
                                                            alt={track.albumName}
                                                            src={track.albumImage.url}
                                                            height={track.albumImage.height}
                                                            width={track.albumImage.width}
                                                        />
                                                    </Grid>
                                                    <Grid item component="div">
                                                        {track[column.id]}
                                                    </Grid>
                                                </Grid>
                                                : track[column.id]
                                            }
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>

            </Table>

        </div>
    );
};

export default TracksTable;
