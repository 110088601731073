import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
        marginTop: "20px"
    },
    space: {
        marginTop: "20px"
    },
    title: {

    }
}));

export default function PaperSheet(
    props
) {
    const classes = useStyles();

    return (
        <div>
            <Paper className={classes.root}>
                <Typography variant="h5" component="h3" >
                    Step 1. Search...
                </Typography>
                <Typography className={classes.space} component="p" align='justify'>
                    Choose a parameter to generate a list of songs choose from. Search for track, artist, or genre. Or choose from the playlist below that you already have...
                </Typography>
            </Paper>
        </div>
    );
}
