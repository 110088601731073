import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {Grid, Paper} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Container from "@material-ui/core/Container";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import {getTracks, getPlaylistTracks} from '../requests';
import PlaylistImage from '../playlist/PlaylistImage';
import StepOneCard from "../components/StepOneCard";
import NoSearchResultsSnackbar from '../components/NoSearchResultsSnackbar';


const useStyles = makeStyles(theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    form: {
        marginTop: theme.spacing(4)
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    playlistContainer: {
        paddingTop: '20px',
        color: theme.palette.secondary.contrastText
    },
    playlists: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        overflow: 'hidden',
        marginTop: '20px',
        padding: '5px'
    },
    gridList: {
        cursor: 'pointer',
        maxWidth: '900px',
        padding: '20px',
        backgroundColor: theme.palette.background.paper
    }
}));

export const Search = ({
    playlists,
    setSearchResults,
    history,
    setIsLoading,
    accessToken,
    setLoggedIn
}) => {
    const classes = useStyles();
    const [song, setSong] = useState('');
    const [playlist, setPlaylist] = useState('');
    const [displayNoResults, setDisplayNoResults] = useState(false);

    if (!accessToken) {
        setLoggedIn(false);
        return <Redirect to='/'/>
    }

    const handleChange = e => {
        setSong(e.target.value)
    };

    const handleTileClick = value => {
        setPlaylist(value);

        setIsLoading(true);
        return getPlaylistTracks(accessToken, value.id)
            .then((tracks) => {
                setSearchResults(tracks);
                return tracks;
            })
            .then((tracks) => {
                if (!tracks.length){
                    throw Error('No search results!');
                }
                history.push('/search-results');
            })
            .catch((error) => {
                setDisplayNoResults(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSubmit = e => {
        e.preventDefault();

        const searchParams = {
            q: song,
            type: 'track',
            market: 'US',
            limit: 20
        };

        setIsLoading(true);
        return getTracks(accessToken, searchParams)
            .then((tracks) => {
                setSearchResults(tracks);
                return tracks;
            })
            .then((tracks) => {
                if (!tracks.length){
                    throw Error('No search results!');
                }
                history.push('/search-results');
            })
            .catch((error) => {
                setDisplayNoResults(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <Container maxWidth="md">
                <Grid container className='searchBar'>
                    <Grid item xs={12}>
                        <form className={classes.form} onSubmit={handleSubmit} noValidate>
                            <Paper className={classes.root}>
                                <InputBase
                                    className={classes.input}
                                    placeholder="Enter search criteria..."
                                    inputProps={{'aria-label': 'enter search criteria'}}
                                    onChange={handleChange}
                                    value={song}
                                    autoFocus={true}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSubmit(e);
                                        }
                                    }}
                                />
                                <IconButton
                                    className={classes.iconButton}
                                    type='submit'
                                    aria-label="search"
                                    component="button"
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    <SearchIcon/>
                                </IconButton>
                            </Paper>
                        </form>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12}>
                        <StepOneCard/>
                    </Grid>
                </Grid>

                {playlists.length > 0 &&
                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.playlists}>
                            <GridList cellHeight={180} cols={4} className={classes.gridList}>

                                {playlists.map(playlist => (
                                    <GridListTile key={playlist.id} onClick={() => handleTileClick(playlist)}>
                                        <PlaylistImage playlist={playlist}/>
                                        <GridListTileBar
                                            title={playlist.name}
                                            subtitle={<span># tracks: {playlist.numTracks}</span>}
                                        />
                                    </GridListTile>
                                ))}

                            </GridList>
                        </div>
                    </Grid>
                </Grid>
                }
            </Container>

            <NoSearchResultsSnackbar showError={displayNoResults} setShowError={setDisplayNoResults} />
        </>
    );
};

export default Search;
