import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const props = {
    nodeEnviroment: process.env.NODE_ENV,
    clientPort: process.env.PORT ? process.env.PORT : 3000,
    serverPort: process.env.NODE_ENV === 'production'? 8888 : 3001,
    spotifyApiId: "3e12c3da330a450e9affb3d25885f022",
    spotifyClientSecret: "e48a9156a74f4069a242916cfdb80bb3",
}

ReactDOM.render(<App {...props} appBaseUrl={process.env.NODE_ENV === 'production'? "https://sortify.now.sh" : `http://localhost:${props.serverPort}`}/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

